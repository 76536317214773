import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../css/register.css";
import { UserContext } from "../context/User";
const LoginPage = () => {
  const { user, setUser } = React.useContext(UserContext);
  const [checkForm, setCheckForm] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault()
    let name = event.target.Name?.value
    let email = event.target.Email?.value
    let password = event.target.Password?.value
    console.log(name,email,password,event.target)

    if(checkForm) {
      if(!name || !email || !password) {
        toast.error("Please fill the form")
        return;
      }
    }else {
      if(!email || !password) {
        toast.error("Please fill the form")
        return;
      }
    }
    let request = await fetch(`https://www.aioncharts.com/api/auth/${checkForm ? "register" : "login"}`,{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,email,password
      })
    })
    let response = await request.json();
    if(response.success) {
      console.log(response)
      if(checkForm) {
        toast.success("Register success")
        localStorage.setItem("token",response?.data?.token)
        setUser(response?.data?.user)
        window.location.href = "/"
      }else {
        toast.success("Register success")
        localStorage.setItem("token",response?.data?.token)
        setUser(response?.data?.user)
        window.location.href = "/"
      }
    }else {
      toast.error("An error occurred! Please try again later")
    }
  }
  return (
    <section className="loginpage">
      <div className="loginpage-wrapper">
        <div className="loginpage-campaign-wrapper">
          <div className="loginpage-campaign-container">
            <div className="loginpage-campaign-text-container">
              <div className="loginpage-campaign-text font-bold">
                <h1>Enhance your trading experience</h1>
                <p>
                  We create next-gen visualizations to help the world see
                  markets smarter.
                </p>
              </div>
            </div>
            <div className="loginpage-campaign-bottom-information">
              <Link className="loginpage-campaign-home-navigation" to="/">
                Back To Home
              </Link>
            </div>
          </div>
        </div>
        <div className="loginpage-register-wrapper">
          <div className="loginpage-register-container">
            <div className="loginpage-register-box">
              <h1 className="registerlogin-text font-bold ">
                {checkForm ? "Register" : "Login"}
              </h1>
              <form className="flex flex-col gap-6" onSubmit={e => handleSubmit(e)}>
              <div className="loginpage-register-form-wrapper">
              {checkForm &&  (
                <div className="input-box">
                  <input
                    type="text"
                    className="register-input !py-4"
                    placeholder="Name"
                    name="Name"
                  />
                </div>
                )}
                 
                  <div className="input-box ">
                    <input
                      type="email"
                      className="register-input !py-4"
                      placeholder="Email"
                      name="Email"
                    />
                  </div>

                <div className="input-box">
                  <input
                    type="password"
                    className="register-input !py-4"
                    placeholder="Password"
                    name="Password"
                  />
                </div>
              </div>
              <div className="loginpage-register-form-checkpassword">
                <div className="form-checkpassword">
                  <input type="checkbox" id="checkpassword" />
                  <label htmlFor="checkpassword">Remember Password</label>
                </div>
              </div>
              <div
                onClick={() => setCheckForm(!checkForm)}
                className="account-box text-sm font-semibold"
              >
                <h5>
                  {checkForm
                    ? "Do you Already have an Account ?"
                    : "If you want to open an account"}
                </h5>
              </div>
              <div className="loginpage-register-button">
                <button type="submit"> {checkForm ? "Register" : "Login"}</button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
