import React from "react";
import "../css/header.css";
import { BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";
import imagelogo from "../images/textlogo.png";
import { UserContext } from "../context/User";
const Header = () => {
  let [modal,setModal] = React.useState(false)
  let {user} = React.useContext(UserContext);
  console.log(user)
  return (
    <div className="header-global-box">
      <div className="campaign">
        <div className="campaign-container">
          <div className="campaign-wrapper">
            <div className="campaign-text">
              <a>Use Code ''A10'' for %10 off your first order!</a>
            </div>
          </div>
        </div>
      </div>
      <header>
        <div className="header-container">
          <div className="header-wrapper">
            <div className="header-logo">
              <img src={imagelogo} alt="" />
            </div>
            <ul className="hidden md:flex items-center  gap-8">
            <li>
                <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/">
                  Homepage
                </a>
              </li>
              <li>
                <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/">
                  Discord
                </a>
              </li>
              <li>
                {!user ? <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/register">
                  Login
                </a> : <div className="flex gap-2 group hover:cursor-pointer items-center justify-center">
                  <div className="w-7 h-7 rounded-full bg-red-500 group-hover:bg-red-400 transition-all flex items-center justify-center">
                    {user?.name[0]}
                  </div>
                  <span className="text-gray-300 px-2 group-hover:text-white transition-all font-semibold text-md">
                    {user?.name}
                  </span>
                </div>
                }
              </li>
            </ul>

            <button className="md:hidden" onClick={() => setModal(!modal)}>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>
            </button>
          </div>
        </div>
        {
          modal && <div className="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-60 backdrop-blur-md z-50">
            <div className="w-full h-screen flex flex-col p-8">
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold">MENU</h1>

                <button onClick={() => setModal(!modal)}>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z"></path></svg>
                </button>
                </div>
                <ul className="flex flex-col mt-8 gap-4">
            <li>
                <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/">
                  Homepage
                </a>
              </li>
              <li>
                <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/">
                  Discord
                </a>
              </li>
              {!user && <li>
                <a className="text-gray-300 p-2 hover:text-white transition-all font-semibold text-md" href="/register">
                  Login
                </a>
              </li> }
              {
                user && <li>
                <div className="flex gap-2 group hover:cursor-pointer items-center justify-start">
                  <div className="w-7 h-7 rounded-full bg-red-500 group-hover:bg-red-400 transition-all flex items-center justify-center">
                    {user?.name[0]}
                  </div>
                  <span className="text-gray-300 px-2 group-hover:text-white transition-all font-semibold text-md">
                    {user?.name}
                  </span>
                </div>
              </li>
              }
            </ul>
            </div>
            </div>
        }
      </header>
    </div>
  );
};

export default Header;
