import React from 'react'

function Member() {
  let [member,setMember] = React.useState('999+')
  React.useEffect(() => {
    fetch('http://localhost:5000/api/auth/users')
      .then(res => res.json())
      .then(data => setMember(data.data))
  }, [])
  return (
    <div className='w-[90%] sm:w-[75%] bg-gradient-card rounded mx-auto my-16 p-4'>
      <div className='flex flex-col items-center justify-center'>
        <h1 className='text-4xl text-white font-bold text-center'>
          Tamı tamına <span className='text-green-400'>{member}+</span> üye olduk!
        </h1>
        <h2 className='text-3xl text-gray-200 font-bold text-center'>
          Sende aramıza katıl!
        </h2>
        <a href='/register' className='bg-green-400 text-white font-bold text-lg px-8 py-2 rounded mt-4'>
          Üye Ol
        </a>
      </div>
    </div>
  )
}

export default Member