import React from "react";
import IndıcatorAbout from "../components/IndicatorAbout";
import Blog from "../components/Blog";
import PrıceSection from "../components/PriceSection";
import Questions from "../components/Questions";
import HomePages from "../components/HomePages";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Member from "../components/Member";

const HomePage = () => {
  return (
    <div>
      <Header />
      <HomePages />
      <IndıcatorAbout />
     {/*  <Blog /> */}
      <PrıceSection />
      <Member/>
      <Questions />
      <Footer />
    </div>
  );
};

export default HomePage;
